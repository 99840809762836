import { trpc } from "@web/trpc/client";
import { TrpcErrorHandler } from "@web/trpc/trpc-error-handler";
import type { API } from "@web/trpc/types";
import { useCallback, useMemo } from "react";
import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import secureLocalStorage from "react-secure-storage";
import { reportAddToCart, reportRemoveFromCart } from "@web/lib/analytics";
import { captureError } from "@web/utils/error-utils";

type UseCartArgs<Create extends boolean> = {
  create?: Create;
  centerId?: string;
  placeholderData?: API.Cart;
};

type CartQueryResult<TCreate extends boolean> = UseQueryResult<
  TCreate extends true ? API.Cart : API.Cart | null
>;

export function useCart<TCreate extends boolean = false>(args?: UseCartArgs<TCreate>): CartQueryResult<TCreate> {
  return useQuery({
    queryKey: ["cart"],
    placeholderData: args?.placeholderData,
    queryFn: async () => {      
      let cartId = getLocalCartId();
      if (!cartId) {
        if (!args?.create || !args.centerId) return null;

        // Create a new cart
        cartId = await trpc.cart.create.mutate({
          centerId: args.centerId,
        });

        localStorage.setItem("ss_cart_id", cartId);
      }

      return await trpc.cart.get.query({ cartId });
    },
  });
}

export function useCreateCartMutation() {
  return useMutation({
    mutationFn: async (centerId: string) => {
      const cartId = await trpc.cart.create.mutate({
        centerId: centerId,
      });
    
      localStorage.setItem("ss_cart_id", cartId);
    }
  });
}

/**
 * Retrieves the cart ID from local storage.
 *
 * @returns {string | null}
 */
export function getLocalCartId(): string | null {
  if (typeof localStorage === "undefined") return null;

  return localStorage.getItem("ss_cart_id");
}

export function deleteLocalCartId() {
  const cartId = localStorage.getItem("ss_cart_id");
  if (cartId)
    secureLocalStorage.removeItem(`ss_checkout_${cartId}`);
  localStorage.removeItem("ss_cart_id");
}

export function useDeleteLocalCart() {
  const queryClient = useQueryClient();
  return () => {
    deleteLocalCartId();
    queryClient.resetQueries({ queryKey: ["cart"] });
  };
}

type UseAddToCartArgs = {
  productId: string;
  centerId: string;
  qty?: number;
  month?: number;
  overrideCenter?: boolean,
};

/**
 * Gets the mutation function to add a product to the cart and track the status of the request.
 *
 * @returns
 */
export function useAddToCart() {
  // Retrieves the cartId from local storage
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args: UseAddToCartArgs) => {
      let cartId = getLocalCartId();

      // Checks that the cart exists
      const cartExists = cartId
        ? await trpc.cart.get
            .query({ cartId })
            .then(() => true)
            .catch(() => false)
        : false;

      if (cartId && args.overrideCenter === true) {
        await trpc.cart.modifyCartCenter.mutate({cartId, centerId: args.centerId})
      }

      // Creates the cart
      if (!cartId || !cartExists) {

        cartId = await trpc.cart.create.mutate({
          centerId: args.centerId,
        });

        localStorage.setItem("ss_cart_id", cartId);
      }

      // Add the line to the existing one
      const notices = await trpc.cart.upsertLine.mutate({
        cartId,
        productId: args.productId,
        qty: args.qty ?? 1,
        month: args.month ?? 1,
      });

      notices.forEach((notice) => {
        switch (notice.type) {
          case "success":
            toast.success(notice.message);
            break;
          case "error":
            toast.error(notice.message);
            break;
          case "info":
            toast.info(notice.message);
            break;
          case "warning":
            toast.warning(notice.message);
            break;
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] })
        .then(() => {
          const cart = queryClient.getQueryData(['cart']) as API.Cart | null;

          if (!cart?.total.ttc) {
            captureError(new Error(`Can't retrieve cart after invalidation in AddToCart for repporting to GTM`));
            return;
          }

          reportAddToCart({cart});
        });
    },
    onError: (e) => {
      const error = TrpcErrorHandler.parseError(e);
      toast.error(error.message);
    }
  });
}

/**
 * Checks if the product is in the customer cart or not
 *
 * @param args
 * @returns {boolean}
 */
export function useInCart(args: { id: string; centerId: string }): boolean {
  const { data: cart, status } = useCart({ centerId: args.centerId });
  return useMemo(() => {
    if (status !== "success" || !cart) return false;

    return cart.lines.some((line) => line.box.productId === args.id);
  }, [status, cart, args.id]);
}

export function useCartLine(cartId: string, line: API.CartLine) {
  const queryClient = useQueryClient();

  const upsertLine = useMutation({
    mutationFn: async (data: API.CartLineUpsertInput) => {
      const notices = await trpc.cart.upsertLine.mutate(data);
      notices.forEach((notice) => {
        switch (notice.type) {
          case "success":
            toast.success(notice.message);
            break;
          case "error":
            toast.error(notice.message);
            break;
          case "info":
            toast.info(notice.message);
            break;
          case "warning":
            toast.warning(notice.message);
            break;
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] })
        .then(async () => {
          const cart = queryClient.getQueryData(['cart']) as API.Cart | null;
        
          if (!cart?.total.ttc) {
            captureError(new Error(`Can't retrieve cart after invalidation in UpsertLine for repporting to GTM`));
            return;
          }

          reportAddToCart({cart});
      });
    },
  });

  const removeLine = useMutation({
    mutationFn: (data: { lineId: string; cartId: string }) => {
      return trpc.cart.removeLine.mutate(data);
    },
    onSuccess: () => {
      const delay = 5_000;

      queryClient.invalidateQueries({ queryKey: ["cart"] }).then(async () => {
        const cart = queryClient.getQueryData(['cart']) as API.Cart | null;
        
        if (!cart) {
          captureError(new Error(`Can't retrieve cart after invalidation in RemoveLine for repporting to GTM`));
          return;
        }

        reportRemoveFromCart({cart});
      });

      toast.success(`${line.box.name} a bien été retiré de votre panier`, {
        duration: delay,
        cancel: {
          label: "Annuler",
          onClick: () =>
            upsertLine.mutate({
              cartId,
              productId: line.box.productId,
              qty: line.qty,
              month: line.month,
            }),
        },
      });
    },
  });

  const handleQtyChange = useCallback(
    (qty: number) => {
      // Remove product from cart
      if (qty === 0) {
        removeLine.mutate({ lineId: line.id, cartId });
      } else {
        upsertLine.mutate({ cartId, productId: line.box.productId, qty });
      }
    },
    [cartId, line.id, line.box.productId, removeLine, upsertLine],
  );

  const error = upsertLine.error ?? removeLine.error;

  return {
    handleQtyChange,
    loading: upsertLine.isPending || removeLine.isPending,
    error: error ? TrpcErrorHandler.parseError(error).message : null,
  };
}

/**
 * On cache le formulaire de coupon si le panier contient des prix barrés 
 */
export function useShowCouponForm() : boolean {
  const cartQuery = useCart();
  const cart = cartQuery.data;
 
  if (!cart) return false;

  const hasDiscountedPrice = cart.lines.some(line => line.bookingFees.discountedPrice || line.box.discountedPrice || line.rentInAdvance?.discountedPrice);

  return !hasDiscountedPrice;
}